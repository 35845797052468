// Cspell:ignore RRNL
import { BookmarkAction, FollowAction, FollowType, notEmpty } from "@product/scmp-sdk";
import deepmerge from "deepmerge";
import { useAtomValue, useSetAtom } from "jotai";
import difference from "lodash/difference";
import union from "lodash/union";
import qs from "qs";
import { useCallback, useEffect } from "react";
import { commitMutation, fetchQuery, graphql } from "react-relay";
import type { RRNLRequestError } from "react-relay-network-modern";
import useSWRImmutable from "swr/immutable";

import { config } from "shared/data";
import { accountAtom } from "shared/lib/account/atoms";

import { useSnackbarSimple } from "scmp-app/components/common/snackbar/hooks";
import {
  StyledSnackbarAction,
  StyledSnackbarMessage,
} from "scmp-app/components/content/content-bookmark/styles";
import type {
  BookmarkLocation,
  BookmarkQueryStringPayload,
} from "scmp-app/components/content/content-bookmark/types";
import {
  SnackbarMessageContainer,
  SnackbarViewAllLink,
} from "scmp-app/components/follow-button/styles";
import { bookmarkAtom } from "scmp-app/components/header/header-bookmark/atoms";
import { useLoginDialogStateHelper } from "scmp-app/components/login-dialog/hooks";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { UntypedGA4Event } from "scmp-app/components/tracking/google-tag-manager/types";
import { createClientUserServiceClientEnvironment } from "scmp-app/lib/relay/environment.client";
import type { hooksBookmarkActionMutation } from "scmp-app/queries/__generated__/hooksBookmarkActionMutation.graphql";
import type { hooksFetchUserServiceQuery } from "scmp-app/queries/__generated__/hooksFetchUserServiceQuery.graphql";
import type { hooksFollowActionMutation } from "scmp-app/queries/__generated__/hooksFollowActionMutation.graphql";

import type { State } from "./atoms";
import { userServiceAtom } from "./atoms";

export const useUserServiceInit = () => {
  const { isLoggedIn } = useAtomValue(accountAtom);
  const environment = createClientUserServiceClientEnvironment();
  const setUserServiceState = useSetAtom(userServiceAtom);

  const fetch = useCallback(async () => {
    const data = await fetchQuery<hooksFetchUserServiceQuery>(
      environment,
      graphql`
        query hooksFetchUserServiceQuery {
          bookmarks(first: 100) {
            edges {
              node {
                targetEntityId
              }
            }
          }
          follows(first: 100) {
            edges {
              node {
                targetEntityId
                type
              }
            }
          }
        }
      `,
      {},
      {
        fetchPolicy: "store-or-network",
      },
    ).toPromise();

    if (!notEmpty(data)) return;

    const state: Omit<State, "mutate"> = {
      bookmarks:
        data?.bookmarks.edges
          .filter(({ node }) => notEmpty(node))
          .map(({ node }) => node.targetEntityId) ?? [],
      follows:
        data?.follows.edges
          .filter(({ node }) => notEmpty(node))
          .map(({ node }) => `${node.type}:${node.targetEntityId}`) ?? [],
    };

    return state;
  }, [environment]);

  const { data, mutate } = useSWRImmutable(isLoggedIn ? "user-service-init" : null, fetch, {
    refreshInterval: 1000 * 30,
    revalidateOnMount: true,
  });

  useEffect(() => {
    if (!data && !mutate) return;

    setUserServiceState({
      ...data,
      mutate,
    });
  }, [data, mutate, setUserServiceState]);
};

const newList = (isAdd: boolean, oldList: string[], newList: string[]) =>
  isAdd ? union(oldList, newList) : difference(oldList, newList);

export const useUserService = () => {
  const { bookmarks, follows, mutate } = useAtomValue(userServiceAtom);
  const { handleOpenSnackbar } = useSnackbarSimple();
  const { isLoggedIn } = useAtomValue(accountAtom);
  const { openLoginDialog } = useLoginDialogStateHelper();
  const { toggleBookmarkRippleEffect, toggleShowNewBookmarkAnimation } = useAtomValue(bookmarkAtom);
  const environment = createClientUserServiceClientEnvironment();

  const mapFollowType = (type: string) => {
    switch (type.toLowerCase()) {
      case "author":
        return FollowType.Author;
      case "section":
        return FollowType.Section;
      case "topic":
        return FollowType.Topic;
    }
  };

  const checkIsBookmarked = useCallback(
    (entityId: string) => bookmarks?.some(targetEntityId => targetEntityId === entityId) ?? false,
    [bookmarks],
  );

  const checkIsFollowed = useCallback(
    (type: FollowType, entityId: string) => {
      const followType = mapFollowType(type);
      if (followType === undefined) return false;
      return follows?.some(follow => follow === `${followType}:${entityId}`) ?? false;
    },
    [follows],
  );

  const bookmarkAction = useCallback(
    async (
      action: BookmarkAction,
      entityId: string,
      trackings?: {
        ga4Events?: Record<"click", UntypedGA4Event>;
        location?: BookmarkLocation;
      },
      callback?: () => void,
    ) => {
      const ga4ActionType = action === BookmarkAction.Bookmark ? "add" : "remove";

      if (trackings?.ga4Events?.click) {
        sendGA4Tracking<true>(
          deepmerge(trackings.ga4Events.click, {
            customized_parameters: {
              action_type: ga4ActionType,
            },
          }),
          {
            untyped: true,
          },
        );
      }

      const handleLoggedOutAction = () => {
        const queryStringPayload: BookmarkQueryStringPayload = {
          action: {
            bookmark: entityId,
          },
        };
        const accountUrl = new URL(window.location.href);
        accountUrl.search = qs.stringify(queryStringPayload);
        openLoginDialog({
          description: "Login or register to save your favourite articles.",
          destination: accountUrl.toString(),
          ga4CustomParameter: {
            trigger_point: "bookmark",
          },
          title: "Bookmark this story",
        });
      };

      if (!isLoggedIn) {
        handleLoggedOutAction();
        return;
      }
      if (!mutate) return;

      try {
        await mutate(
          data =>
            new Promise((resolve, reject) => {
              commitMutation<hooksBookmarkActionMutation>(environment, {
                mutation: graphql`
                  mutation hooksBookmarkActionMutation(
                    $action: BookmarkAction!
                    $entityIds: [String!]!
                  ) {
                    bookmarkResults: bookmark(action: $action, entityIds: $entityIds) {
                      edges {
                        node {
                          targetEntityId
                        }
                      }
                    }
                  }
                `,
                onCompleted: ({ bookmarkResults }) => {
                  resolve({
                    ...data,
                    bookmarks: newList(
                      action === BookmarkAction.Bookmark,
                      data?.bookmarks ?? [],
                      bookmarkResults.edges.map(({ node }) => node.targetEntityId),
                    ),
                  });

                  if (trackings?.ga4Events?.click) {
                    sendGA4Tracking<true>(
                      deepmerge(trackings.ga4Events.click, {
                        action: "sys",
                        customized_parameters: {
                          action_type: ga4ActionType,
                        },
                      }),
                      {
                        untyped: true,
                      },
                    );
                  }

                  handleOpenSnackbar({
                    duration: 4000,
                    leftElement: (
                      <StyledSnackbarMessage>
                        {action === BookmarkAction.Bookmark ? "Bookmarked" : "Unbookmarked"}
                      </StyledSnackbarMessage>
                    ),
                    rightElement: (
                      <StyledSnackbarAction
                        pathname={`${config.account.scmpAccountHost}/manage/bookmark`}
                      >
                        View all
                      </StyledSnackbarAction>
                    ),
                  });

                  if (action === BookmarkAction.Bookmark) {
                    toggleBookmarkRippleEffect();
                    toggleShowNewBookmarkAnimation(true);
                  }

                  callback?.();
                },
                onError: error => {
                  reject(error);
                },
                variables: { action, entityIds: [entityId] },
              });
            }),
          {
            optimisticData: data => ({
              ...data,
              bookmarks: newList(action === BookmarkAction.Bookmark, data?.bookmarks ?? [], [
                entityId,
              ]),
            }),
            populateCache: true,
            revalidate: false,
            rollbackOnError: true,
          },
        );
      } catch (error) {
        const relayError = error as RRNLRequestError;
        if (relayError?.res?.status === 401) {
          handleLoggedOutAction();
        }
      }
    },
    [
      environment,
      handleOpenSnackbar,
      isLoggedIn,
      mutate,
      openLoginDialog,
      toggleBookmarkRippleEffect,
      toggleShowNewBookmarkAnimation,
    ],
  );

  const followAction = useCallback(
    async (
      action: FollowAction,
      type: FollowType,
      entityIds: string[],
      callback?: (error?: RRNLRequestError) => void,
    ) => {
      if (!isLoggedIn || !mutate) return;

      const followType = mapFollowType(type);
      if (followType === undefined) return;

      try {
        await mutate(
          data =>
            new Promise((resolve, reject) => {
              commitMutation<hooksFollowActionMutation>(environment, {
                mutation: graphql`
                  mutation hooksFollowActionMutation(
                    $action: FollowAction!
                    $type: FollowType!
                    $entityIds: [String!]!
                  ) {
                    followResults: follow(action: $action, type: $type, entityIds: $entityIds) {
                      edges {
                        node {
                          targetEntityId
                          type
                        }
                      }
                    }
                  }
                `,
                onCompleted: ({ followResults }) => {
                  resolve({
                    ...data,
                    follows: newList(
                      action === FollowAction.Follow,
                      data?.follows ?? [],
                      followResults.edges.map(({ node }) => `${node.type}:${node.targetEntityId}`),
                    ),
                  });

                  handleOpenSnackbar({
                    duration: 4000,
                    leftElement: (
                      <SnackbarMessageContainer>
                        {action === FollowAction.Follow ? "Following" : "Unfollowed"}
                      </SnackbarMessageContainer>
                    ),
                    rightElement: (
                      <SnackbarViewAllLink
                        pathname={config.account.scmpAccountHost + "/manage/following"}
                      >
                        View all
                      </SnackbarViewAllLink>
                    ),
                  });

                  callback?.();
                },
                onError: error => {
                  reject(error);
                },
                variables: { action, entityIds, type: followType },
              });
            }),
          {
            optimisticData: data => ({
              ...data,
              follows: newList(
                action === FollowAction.Follow,
                data?.bookmarks ?? [],
                entityIds.map(entityId => `${followType}:${entityId}`),
              ),
            }),
            populateCache: true,
            revalidate: false,
            rollbackOnError: true,
          },
        );
      } catch (error) {
        callback?.(error as RRNLRequestError);
      }
    },
    [environment, handleOpenSnackbar, isLoggedIn, mutate],
  );

  return {
    bookmarkAction,
    checkIsBookmarked,
    checkIsFollowed,
    followAction,
  };
};
