/**
 * @generated SignedSource<<5a6c2b315bd381c4a9e39ceb4cbb6d60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BookmarkAction = "Bookmark" | "UnBookmark" | "%future added value";
export type hooksBookmarkActionMutation$variables = {
  action: BookmarkAction;
  entityIds: ReadonlyArray<string>;
};
export type hooksBookmarkActionMutation$data = {
  readonly bookmarkResults: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly targetEntityId: string;
      };
    }>;
  };
};
export type hooksBookmarkActionMutation = {
  response: hooksBookmarkActionMutation$data;
  variables: hooksBookmarkActionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "action"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entityIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "action",
    "variableName": "action"
  },
  {
    "kind": "Variable",
    "name": "entityIds",
    "variableName": "entityIds"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetEntityId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "hooksBookmarkActionMutation",
    "selections": [
      {
        "alias": "bookmarkResults",
        "args": (v1/*: any*/),
        "concreteType": "BookmarkConnection",
        "kind": "LinkedField",
        "name": "bookmark",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BookmarkEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Bookmark",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "hooksBookmarkActionMutation",
    "selections": [
      {
        "alias": "bookmarkResults",
        "args": (v1/*: any*/),
        "concreteType": "BookmarkConnection",
        "kind": "LinkedField",
        "name": "bookmark",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BookmarkEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Bookmark",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c84c9dcab4e375d9c96358eb56bf676a",
    "id": null,
    "metadata": {},
    "name": "hooksBookmarkActionMutation",
    "operationKind": "mutation",
    "text": "mutation hooksBookmarkActionMutation(\n  $action: BookmarkAction!\n  $entityIds: [String!]!\n) {\n  bookmarkResults: bookmark(action: $action, entityIds: $entityIds) {\n    edges {\n      node {\n        targetEntityId\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "da7b1e549171a99ab7937b8911d65c61";

export default node;
