/**
 * @generated SignedSource<<91f8a91446526cb5a6d9318f826526af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type FollowAction = "Follow" | "UnFollow" | "%future added value";
export type FollowType = "Author" | "Section" | "Topic" | "%future added value";
export type hooksFollowActionMutation$variables = {
  action: FollowAction;
  entityIds: ReadonlyArray<string>;
  type: FollowType;
};
export type hooksFollowActionMutation$data = {
  readonly followResults: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly targetEntityId: string;
        readonly type: FollowType;
      };
    }>;
  };
};
export type hooksFollowActionMutation = {
  response: hooksFollowActionMutation$data;
  variables: hooksFollowActionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "action"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v3 = [
  {
    "kind": "Variable",
    "name": "action",
    "variableName": "action"
  },
  {
    "kind": "Variable",
    "name": "entityIds",
    "variableName": "entityIds"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetEntityId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "hooksFollowActionMutation",
    "selections": [
      {
        "alias": "followResults",
        "args": (v3/*: any*/),
        "concreteType": "FollowConnection",
        "kind": "LinkedField",
        "name": "follow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FollowEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Follow",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "hooksFollowActionMutation",
    "selections": [
      {
        "alias": "followResults",
        "args": (v3/*: any*/),
        "concreteType": "FollowConnection",
        "kind": "LinkedField",
        "name": "follow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FollowEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Follow",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ea73745a1b60089ba61fc1831a38f8ce",
    "id": null,
    "metadata": {},
    "name": "hooksFollowActionMutation",
    "operationKind": "mutation",
    "text": "mutation hooksFollowActionMutation(\n  $action: FollowAction!\n  $type: FollowType!\n  $entityIds: [String!]!\n) {\n  followResults: follow(action: $action, type: $type, entityIds: $entityIds) {\n    edges {\n      node {\n        targetEntityId\n        type\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "609ca9e0789a6f11591f7e90529f11c2";

export default node;
