/**
 * @generated SignedSource<<8cff188a8aa7dd2d9f07ae2ca6e15095>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type FollowType = "Author" | "Section" | "Topic" | "%future added value";
export type hooksFetchUserServiceQuery$variables = Record<PropertyKey, never>;
export type hooksFetchUserServiceQuery$data = {
  readonly bookmarks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly targetEntityId: string;
      };
    }>;
  };
  readonly follows: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly targetEntityId: string;
        readonly type: FollowType;
      };
    }>;
  };
};
export type hooksFetchUserServiceQuery = {
  response: hooksFetchUserServiceQuery$data;
  variables: hooksFetchUserServiceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetEntityId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "hooksFetchUserServiceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "BookmarkConnection",
        "kind": "LinkedField",
        "name": "bookmarks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BookmarkEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Bookmark",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "bookmarks(first:100)"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "FollowConnection",
        "kind": "LinkedField",
        "name": "follows",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FollowEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Follow",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "follows(first:100)"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "hooksFetchUserServiceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "BookmarkConnection",
        "kind": "LinkedField",
        "name": "bookmarks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BookmarkEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Bookmark",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "bookmarks(first:100)"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "FollowConnection",
        "kind": "LinkedField",
        "name": "follows",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FollowEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Follow",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "follows(first:100)"
      }
    ]
  },
  "params": {
    "cacheID": "e657d24cf162bd80e55ef1c041fd2d5a",
    "id": null,
    "metadata": {},
    "name": "hooksFetchUserServiceQuery",
    "operationKind": "query",
    "text": "query hooksFetchUserServiceQuery {\n  bookmarks(first: 100) {\n    edges {\n      node {\n        targetEntityId\n        id\n      }\n    }\n  }\n  follows(first: 100) {\n    edges {\n      node {\n        targetEntityId\n        type\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b6301a3e33bc45b303ae831136d4e0e6";

export default node;
